.PowerdBy {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: inherit;
  font-size: 15px;

  background: var(--bg);
  padding: 4px 8px;
  padding-left: 16px;
  border-radius: 8px;

  &:hover {
    text-decoration: none;
    color: inherit;
  }

  span {
    font-weight: 700;
  }
  img {
    transition: 0.2s;
    height: 28px;
  }

  .heart {
    background-color: #fb0100;
    width: 13px;
    height: 13px;
    position: relative;
    transform: rotate(45deg);
    animation: animateHeart 1.2s infinite;
    margin: 0 8px;

    &::before,
    &::after {
      content: "";
      width: 13px;
      height: 13px;
      background-color: #fb0100;
      position: absolute;
      border-radius: 50%;
    }

    &::before {
      left: -8px;
    }

    &::after {
      top: -8px;
    }
  }

  @keyframes animateHeart {
    0% {
      transform: rotate(45deg) scale(0.8);
    }
    5% {
      transform: rotate(45deg) scale(0.9);
    }
    10% {
      transform: rotate(45deg) scale(0.8);
    }
    15% {
      transform: rotate(45deg) scale(1);
    }
    50% {
      transform: rotate(45deg) scale(0.8);
    }
    100% {
      transform: rotate(45deg) scale(0.8);
    }
  }

  @keyframes animateHeart {
    0% {
      transform: rotate(45deg) scale(0.8);
    }
    5% {
      transform: rotate(45deg) scale(0.9);
    }
    10% {
      transform: rotate(45deg) scale(0.8);
    }
    15% {
      transform: rotate(45deg) scale(1);
    }
    50% {
      transform: rotate(45deg) scale(0.8);
    }
    100% {
      transform: rotate(45deg) scale(0.8);
    }
  }
}
