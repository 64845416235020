.ColorPicker {
  display: flex;

  .ColorItem {
    margin-right: 8px;
    height: 32px;
    width: 32px;
    background: #aaa;

    border-radius: 12px;

    box-shadow: 0 0 1px 0px #111;
  }
}
