.Header {
  .push {
  }
  .bar {
    z-index: 1000;
    height: var(--navbarHeight);
    background: var(--navbarBackground);
    position: fixed;
    backdrop-filter: blur(22px);
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(12px);
    top: 0;
    right: 0;
    left: 0;

    .inner {
      height: var(--navbarHeight);
      //   padding: 12px 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;

      .Logo {
        // width: 140px;
        img {
          height: 44px;
          // max-height: 32px;
        }

        &:hover {
          transform: scale(1.05);
        }
      }

      .links {
        a {
          color: var(--text1);
          margin: 0 12px;
          font-weight: 600;
        }
      }

      button {
        padding: 4px 12px;
        color: var(--sub_);
        border-color: var(--sub_);
        font-size: var(--fs4);
        // line-break: no;
        i,
        svg {
          margin-left: 4px;
          font-size: var(--fs4);
          color: var(--sub_);
        }
      }

      .nav {
        a {
          font-weight: 500;
          padding: 0 12px;
          font-size: 18px;
          text-transform: uppercase;
        }
      }
    }
  }

  .MobileHeader {
    .inner {
      position: fixed;
      top: 90px;
      left: 0;
      right: 0;
      height: 100vh;
      width: 100vw;
      bottom: 0;
      z-index: 99999;
      background: var(--bgSub);

      .Logo {
        img {
          height: 22px;
        }
      }

      button {
        font-size: 16px;
      }

      a {
        // text-align: right;
        color: var(--text1);
        margin: 4px 32px;
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
}
