.IconBox {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--r2);

  height: 52px;
  width: 52px;
  background: var(--main2);
  margin-bottom: 12px;

  i,
  svg {
    font-size: 32px;
    color: var(--main2_);
  }
}
