.MiniBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  .dot {
    width: 20px;
    height: 20px;
    border-radius: 8px;
    background: var(--main2);
    margin-bottom: 12px;
    margin-right: 4px;
    &.small {
      border-radius: 6px;
      width: 16px;
      height: 16px;
    }
    &.mini {
      border-radius: 5px;
      width: 12px;
      height: 12px;
    }
  }
}
