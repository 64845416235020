.ProfileImage {
  background: var(--sub);
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 12px;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
  i,
  svg {
    height: 20px;
    height: 20px;
    font-size: 20px;
    color: var(--text4);
  }
}
