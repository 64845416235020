body.dark-theme img {
  filter: brightness(0.8) contrast(1.2);
}

html {
  .lightModeOnly {
    display: block;
  }
  .darkModeOnly {
    display: none;
  }

  background: var(--bg);

  --r1: 0px;
  --r2: 0px;
  --r3: 0px;
  --r4: 8px;

  --navbarHeight: 90px;
  --navbarBackground: transparent;

  --bg: #fefefe;
  --bgSub: #f3f0f0;

  --black: #000;
  --white: #fff;
  --blue: #0a3fc2;
  --blue_: #fff;
  --red: #a34637;
  --blue_: #fff;
  --green: #33cc34;
  --green_: #fff;
  --yellow: #dbbf08;
  --yellow_: #000;
  --brown: #4e3835;
  --brown_: #fff;

  --text1: #000;
  --text2: #2e2e2e;
  --text3: #4e4e4e;
  --text4: #6e6e6e;

  --main: #1d2673; /* o2 Blau im Dunkelmodus */
  --main_: #fff;

  --main2: #0465f7;
  --main2_: #fff;

  // #4E3835
  // #3B6351
  // #A34637

  --sub: #eee;
  --subDarker: #0a3fc2;
  --subBlur: #0a3fc2;
  --sub_: #fff;

  // FontAwesome
  --fa-primary-color: var(--main);
  --fa-secondary-color: var(--sub);
  --fa-secondary-opacity: 0.5;

  // Not Colors
  --border: 0.5px solid #eeeded;
  --borderActive: 1px solid var(--text4);
}

@media (prefers-color-scheme: dark) {
  .lightModeOnly {
    display: none !important;
  }
  .darkModeOnly {
    display: block !important;
  }

  html {
    --bg: #000;
    --bgSub: #151515;

    --black: #fff;
    --white: #000;
    --blue: #0a3fc2;
    --blue_: #000;
    --red: #cf1717;
    --blue_: #000;
    --green: #33cc34;
    --green_: #000;
    --yellow: #dbbf08;
    --yellow_: #000;

    --text1: #fff;
    --text2: #e5e5e5;
    --text3: #bbbbbb;
    --text4: #8e8e8e;

    --main: #1d2673; /* o2 Blau im Dunkelmodus */
    --main_: #fff;

    --main2: #0465f7;
    --main2_: #fff;

    // #4E3835
    // #3B6351
    // #A34637

    --sub: #222;
    --subDarker: #0465f7;
    --subBlur: #0465f7;
    --sub_: #fff;

    // FontAwesome
    --fa-primary-color: var(--main);
    --fa-secondary-color: var(--sub);
    --fa-secondary-opacity: 0.5;

    // Not Colors
    --border: 0.5px solid #eeeded;
    --borderActive: 1px solid var(--text4);
  }
}
