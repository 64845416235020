.RenderContent {
  .heading {
    display: block;
    &.level-1 {
      color: var(--main);
      font-size: 32px;
    }
    &.level-2 {
      color: var(--main);
      font-size: 32px;
    }
    &.level-3 {
      font-size: 22px;
    }
  }
  .paragraph {
    display: block;
    font-size: 16px;
    color: var(--text2);
    padding-bottom: 32px;
  }
}
