.SmartPhonePageFullScreen {
  .inner {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999999999;

    background: var(--bg);

    padding: 0;

    .titleBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 12px;

      span.title {
        font-size: 20px;
        color: var(--text1);
        font-weight: 500;
      }
      span.manufacturer {
        margin-bottom: -4px;
        color: var(--text4);
      }
    }

    .snapList {
      overflow-x: scroll !important;

      width: 100vw;
      scroll-snap-type: mandatory;
      scroll-snap-points-y: repeat(300px);
      scroll-snap-type: y mandatory;

      > .item {
        scroll-snap-align: start;
      }
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }
}
