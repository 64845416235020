.DicksterTextWoEsGibBox {
  .container {
    text-align: center;
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding-top: 64px;

    h1 {
      font-size: 90px;
      color: var(--main2);
    }
  }
}

@media screen and (max-width: 768px) {
  .DicksterTextWoEsGibBox .container h1 {
    font-size: 60px; /* Anpassung der Schriftgröße für Tablets */
  }
}

@media screen and (max-width: 480px) {
  .DicksterTextWoEsGibBox .container h1 {
    font-size: 40px; /* Anpassung der Schriftgröße für Handys */
  }
}
