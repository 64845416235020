.LatestNewsBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .PartnerItem {
    margin-right: 8px;
    margin-bottom: 8px;
    .inner {
      img {
        max-height: 100px;
        max-width: 140px;
        width: auto;

        border-radius: 24px;
      }
    }
  }
}
