.StaticImage {
  max-width: 100%;
  img {
    max-width: 100%;
  }

  .asBackground {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    min-height: 32px;
  }
}
