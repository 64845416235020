body {
  font-family: "Poppins", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  --fs1: 48px;
  --fs2: 38px;
  --fs3: 22px;
  --fs4: 16px;
  --fs5: 12px;
}

span,
p,
b {
  color: var(--text3);
  white-space: pre-wrap;
}
a {
  color: var(--blue);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--text2);
}

h1,
.heading.level-1 {
  font-weight: 800;
  font-size: var(--fs1);
  color: var(--text1);
}

h2,
.heading.level-2 {
  font-weight: 700;
  font-size: var(--fs2);
}

.heading.level-3,
h3 {
  font-weight: 700;
  font-size: var(--fs3);
}

p,
.paragraph {
  font-size: var(--fs4);
}
