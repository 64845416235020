footer {
  padding: 32px;
  padding-top: 32px;
  padding-bottom: 40px;
  background: var(--sub);

  max-width: 100vw;
  overflow-x: hidden;

  .copyrightArea {
    color: #fffc;
    padding-top: 32px;
    text-align: center;
  }

  .row > div {
    margin-bottom: 24px;
  }

  .items {
    a {
      font-size: var(--fs4);
      color: var(--text1);
      display: block;
    }

    i,
    svg {
      min-width: 25px;
    }

    a .fab {
      padding-right: 6px;
    }
  }

  hr {
    border-color: var(--text4);
    margin-bottom: 8px;
  }

  a {
    color: var(--blue) !important;

    &:hover {
      transform: translateX(2px);
      transition: 0.2s;
      color: var(--sub) !important;
    }

    &:focus {
      transform: translateX(16px) scale(1.05);
      transition: 0.2s;
    }

    transition: 0.6s;
  }

  h3 {
    color: var(--sub);
  }

  .copyright {
    color: var(--text3);
    font-weight: 300;
    font-size: var(--fs5);
  }

  .lowItems a {
    color: var(--text3);
    font-weight: 300;
    font-size: var(--fs5);
    margin-right: 12px;
  }
}
