.NewSmartphoneMessage {
  display: flex;
  flex-direction: column;

  background: var(--blue);
  padding: 16px;
  margin-bottom: 32px;
  margin-top: 8px;
  span.title {
    font-size: 16px;
    color: var(--blue_);
    font-weight: 500;
  }
  span.description {
    font-size: 16px;
    opacity: 0.7;
    color: var(--blue_);
    font-weight: 500;
    margin-bottom: 8px;
  }
  a {
    align-self: flex-start;
    padding: 4px 8px;
    font-size: 18px;
    background: var(--blue_);
    color: var(--blue);
    font-weight: 500;
    opacity: 0.7;
  }
}
