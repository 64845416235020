.EasyBox {
  .iconBox {
  }

  .EasyBoxItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &.odd {
    // background: var(--bgSub);
    .row {
      flex-direction: row-reverse !important;

      .imgBox {
        padding-right: 16px;
        .img {
        }
      }
    }
  }

  &.colored {
    background: var(--main);

    h2,
    h3,
    p {
      color: var(--main_);
    }
    p {
      opacity: 0.9;
    }

    button {
      background: var(--sub);
    }

    .MiniBar {
      background: var(--main_);
      opacity: 0.95;
    }
  }
}
