.ContactPage {
  .container {
    // max-width: 1080px;
    .Logo {
      width: 50%;
      img {
        // height: 64px;
      }
    }

    .socialMedia {
      i,
      svg {
        font-size: 44px;
        margin-top: 24px;
        margin-bottom: 16px;
        color: var(--main2);
      }

      h3 {
        margin-bottom: 4px;
      }

      p {
        color: var(--blue);
      }
    }
  }
}
